import React from "react";

const AboutUs = React.lazy(() => import("../App/Components/AboutUs"));
const ContactUs = React.lazy(() => import("../App/Components/Contact"));
const Services = React.lazy(() => import("../App/Components/Services"));
const Demo = React.lazy(() => import("../App/Components/Demo"));
const Product = React.lazy(() => import("../App/Components/Product"));

const routes = [
  {
    path: "/about",
    exact: true,
    name: "About Us",
    component: AboutUs,
  },
  {
    path: "/contact",
    exact: true,
    name: "Contact Us",
    component: ContactUs,
  },
  {
    path: "/services",
    exact: true,
    name: "Services",
    component: Services,
  },
  {
    path: "/demo",
    exact: true,
    name: "Demo",
    component: Demo,
  },
  {
    path: "/product",
    exact: true,
    name: "Product",
    component: Product,
  },
];

export default routes;
