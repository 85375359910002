import React, { Component, Suspense } from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import routes from "./routes";
import Loader from "../App/Loader";
import Footer from "../App/Layouts/Footer";
import { createMemoryHistory } from 'history'

const Home = React.lazy(() => import("../App/Components/Home"));

const history = createMemoryHistory()

class App extends Component {
  render() {
    const menu = routes.map((route, index) => {
      return route.component ? (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          name={route.name}
          render={(props) => <route.component {...props} />}
        />
      ) : null;
    });

    return (
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <Switch>
            {menu}
            <Route path={["/home", "/"]} component={Home} history={history} />
          </Switch>
          <Footer />
        </Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
