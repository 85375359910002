export const ProductData = [
  { product: "School ERP", link: "", latest: false },
  { product: "Retial ERP", link: "", latest: false },
  { product: "Quiz App", link: "", latest: true },
];

export const CompanyData = [
  { title: "About Us", link: "about" },
  { title: "Services", link: "services" },
  { title: "Careers", link: "career" },
  { title: "Contact", link: "contact" },
];
