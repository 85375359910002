import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../Assets/images/footer/Logo.png";
import BackToTop from "../../Assets/images/footer/back-to-top.png";
import Facebook from "../../Assets/images/footer/facebook.png";
import Twitter from "../../Assets/images/footer/twitter.png";
import Linkedin from "../../Assets/images/footer/linkedin.png";
import Youtube from "../../Assets/images/footer/youtube.png";
import { useWindowWidthAndHeight } from "../../../CustomHook";
import { CompanyData, ProductData } from "./data";

const Footer = () => {
  const [width] = useWindowWidthAndHeight();

  let containerCls =
    width > 768 ? "container th-container pt-5 pb-5 " : "container pt-5 pb-5";

  return (
    <div className="th-footer-bg pt-5 pb-5">
      <div className={containerCls}>
        {/*  */}
        <div className="row pt-md-4 pb-md-4">
          <div className="col-md-5 col-12 th-footer-logo-box">
            <img src={Logo} alt="" className="th-footer-logo mb-3" />
            <p className="mb-0 th-footer-bio">
              One-stop software solutions to take your
              {width > 768 ? <br /> : null} business online.
            </p>
          </div>
          <div className="col-md-7 col-12">
            <div className="row m-0">
              <div className="col-md-5 col-12 th-footer-link-col">
                <div>
                  <p className="th-footer-link-head pb-3 ">Our Products</p>
                  {ProductData &&
                    ProductData.map((item, index) => {
                      return (
                        <Link
                          to={`/${item.link}`}
                          className="th-link"
                          key={index}
                        >
                          <p className="th-footer-link pb-md-3 pb-2">
                            <span className="pr-2">{item.product}</span>
                            {item.latest && (
                              <span className="th-footer-link-latest">
                                New!
                              </span>
                            )}
                          </p>
                        </Link>
                      );
                    })}
                </div>
              </div>
              <div className="col-md-5 col-12 th-footer-link-col">
                <div>
                  <p className="th-footer-link-head pb-3 ">Company</p>
                  {CompanyData &&
                    CompanyData.map((item, index) => {
                      return (
                        <Link
                          to={`/${item.link}`}
                          className="th-link"
                          key={index}
                        >
                          <p className="th-footer-link pb-md-3 pb-2">
                            {item.title}
                          </p>
                        </Link>
                      );
                    })}
                </div>
              </div>
              {width > 768 ? (
                <div className="col-2 text-right pt-1">
                  <img
                    src={BackToTop}
                    alt=""
                    className="th-footer-icon"
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {/*  */}
        {width > 768 ? (
          <div className="pt-5 pb-4">
            <p className="th-footer-mail pt-4">zixwerweb@gmail.com</p>
            <p className="th-footer-contact">
              Reach out @ +91 8171899493 / +91 9454545296
            </p>
          </div>
        ) : (
          <div className="pt-4 pb-4">
            <p className="th-footer-mail text-center">zixwerweb@gmail.com</p>
            <div className="d-flex justify-content-center">
              <p className="th-footer-contact mb-0">Reach out @</p>
              <p className="th-footer-contact pl-2">+91 8171899493</p>
            </div>
            <div className="d-flex justify-content-center">
              <p style={{ visibility: "hidden" }}>Reach out @</p>
              <p className="th-footer-contact pl-4 mb-0">+91 9454545296</p>
            </div>
          </div>
        )}
        <div className="th-footer-divider"></div>
        {/*  */}
        <div className="pt-md-5 pt-4 th-footer-bottom">
          <p className="th-footer-copyright mb-0">
            Copyright © 2021. Zixwer. All rights reserved.
          </p>
          <div className="th-footer-social-icons">
            <a
              href="https://www.linkedin.com/company/zixwer/"
              target="_blank"
              rel="noreferrer"
              className="th-link"
            >
              <img
                src={Linkedin}
                alt=""
                className="pl-3 pr-3 th-footer-linkedin"
              />
            </a>

            <a
              href="https://www.facebook.com/zixwerwebservices/"
              target="_blank"
              rel="noreferrer"
              className="th-link"
            >
              <img
                src={Facebook}
                alt=""
                className="pl-3 pr-3 th-footer-facebook"
              />
            </a>

            <a href="https://twitter.com/zixwer" className="th-link">
              <img
                src={Twitter}
                alt=""
                className="pl-3 pr-3 th-footer-twitter"
              />
            </a>

            <a
              href="https://www.youtube.com/channel/UC4D11fuYoraWloDROi1PS8Q"
              target="_blank"
              rel="noreferrer"
              className="th-link"
            >
              <img
                src={Youtube}
                alt=""
                className="pl-3 pr-3 th-footer-youtube"
              />
            </a>
          </div>
        </div>
        {width < 768 ? (
          <div className="text-center pt-5">
            <img
              src={BackToTop}
              alt=""
              className="th-footer-icon"
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Footer;
